<template>
  <div>
    <Breadcrumbs />
    <b-modal
      id="modal-review"
      ok-only
      okVariant="success"
      okTitle="Ok"
      hide-header="true"
      content-class="review-modal"
      size="sm"
      @ok="clickReviewOk"
    >
      <div class="wrap">
        <label>{{ $t("review_send") }}</label>
        <p>{{ $t("review_thankyou") }}</p>
      </div>
    </b-modal>

    <div class="add-review-page">
      <b-container>
        <div class="title">
          <h3>
            {{ $t("add_review_page_title") }}
            <span v-if="product"> {{ product.name }}</span>
          </h3>
        </div>
        <b-row>
          <b-col lg="5">
            <b-img :src="product.image.url" :alt="product.name" fluid></b-img>
          </b-col>
          <b-col lg="7">
            <div class="review-form">
              <h6>{{ $t("you'rereviewing") }}</h6>
              <div v-for="(ritem, idx) of reviewMeta" :key="idx">
                <ReviewRating
                  :reviewData="ritem"
                  v-model="ratings[idx]"
                ></ReviewRating>
              </div>

              <div class="form-group">
                <label for="review-experience">
                  {{ $t("product_review_experience") }}
                </label>
                <div>
                  <textarea
                    type="text"
                    id="review-experience"
                    class="form-control"
                    cols="40"
                    rows="5"
                    v-model="reviewExperience"
                  ></textarea>
                  <div class="invalid-review-exp"></div>
                </div>
              </div>

              <div class="form-group">
                <label for="review-overview">
                  {{ $t("product_review_opinion") }}
                </label>
                <div>
                  <input
                    type="text"
                    id="review-overview"
                    class="form-control"
                    v-model="reviewOverview"
                  />
                  <div class="invalid-review-overview"></div>
                </div>
              </div>
              <div class="form-group">
                <label for="first-name">
                  {{ $t("product_review_name") }}
                </label>
                <div>
                  <input
                    type="text"
                    id="first-name"
                    class="form-control"
                    v-model="firstName"
                  />
                  <div class="invalid-review-first-name"></div>
                </div>
              </div>
              <button @click="sendReview" class="btn btn-primary">
                {{ $t("send_review") }}
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import ReviewRating from "@/base/core/components/products/product-detail/ReviewRating";

import { createProductReview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
import { mapGetters } from "vuex";

export default {
  name: "AddReview",
  components: {
    // BaseLayout,
    Breadcrumbs,
    ReviewRating,
  },
  data() {
    return {
      test: null,
      firstName: "",
      reviewOverview: "",
      reviewExperience: "",
      ratings: [],
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      reviewMeta: "product/getProductReviewRatingsMetadata",
    }),
  },
  methods: {
    async sendReview() {
      const ratings = [];

      //   this.reviewMeta.forEach((element, index) => {
      //     if (typeof this.ratings[index] != "undefined") {
      //       let obj = { id: element.id, value_id: this.ratings[index] };
      //       ratings.push(obj);
      //     } else {
      //       //alert("AAAHHHHH");
      //     }
      //   });
      const inp = {};
      inp.sku = this.product.sku;
      inp.nickname = this.firstName;
      inp.summary = this.reviewOverview;
      inp.text = this.reviewExperience;
      inp.ratings = ratings;
      this.test = inp;
      Logger.debug("inp", "ProductReview", inp)();
      const retval = await createProductReview(inp);
      Logger.debug("retval", "ProductReview", retval)();
      if (retval != false) {
        this.$bvModal.show("modal-review");
        this.firstName = "";
        this.reviewOverview = "";
        this.reviewExperience = "";
        this.ratings.forEach((element, index) => {
          element = null;
          this.ratings[index] = null;
        });
      }
    },
    clickReviewOk() {
      this.$bvModal.hide("modal-review");
    },
  },
  created() {},
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
};
</script>
